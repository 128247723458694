footer {
    background-color: black;
    padding: 10px 30px;
    width: 100%;
    position: fixed;
    bottom: 0;
}

footer p {
    font-size: 10px;
    margin: 0;
    color: rgba(255,255,255,0.1);
}