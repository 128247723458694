nav {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    background-color: black;
    z-index: 1;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;
}
nav > ul {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
}
nav a {
    color: white;
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
}
nav a:hover {
    color: #EBC849;
}
nav i {
    font-size: 24px;
}

/* Mobile menu styles */
.mobile-menu-button {
    display: none;
    z-index: 10;
}
.mobile-menu-button:hover {
    background-color: #000000;
}

.mobile-menu {
}

.mobile-menu-show {
    flex-direction: column-reverse;
    align-items: center;
    display: flex;
    visibility: visible;
    padding-top: 65px;

}
.mobile-menu-hide {
    display: none;
    visibility: hidden;
}

@media screen and (max-width: 768px) {
    nav {
        padding: 20px;
        width: calc(100% - 40px);
    }
    nav > ul {
        padding: 0px;
    }
    nav > ul:first-child {
        flex-direction: row;
        justify-content: center;
        padding-top: 15px;
        margin-top: 15px;
    }
    nav > ul:first-child::before {
        content: '';
        border-top: 1px solid white;
        position: absolute;
        width: 50%;
        margin-top: -70px;
    }
    nav li {
        padding: 8px 0px;
    }
    .desktop-nav {
        display: none;
    }
    .mobile-menu-button {
        display: block; /* Show the button on mobile */
        background: none;
        border: none;
        color: white;
        font-size: 24px;
        cursor: pointer;
        margin-top: 0px;
        width: auto;
        padding:20px;
        position: fixed;
        right: 0;
    }
    
    .mobile-menu ul {
        display: flex; /* Show/hide the mobile menu */
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        background-color: black;
        width: 100%;
        z-index: 2;
    }
    .mobile-menu ul.mobile-menu-show {
    
    }
    
    .mobile-menu a {
        color: white;
        text-decoration: none;
        padding: 10px 40px;
        font-weight: 600;
    }
    
    .mobile-menu a:hover {
        color: #689775;
    }
}