main.hero {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20em;
    padding-top: 5em;
}
.hero h1 {
    font-size: 4.5em;
    color: #D14636;
    margin: -10px 0px 10px 0px;
  }
main.hero div:first-child {
    display: flex;
    justify-content: center;
    padding-top: 10em;
    flex-direction: column;
    align-items: center;
}
.socials {
    display: flex;
}
.socials a {
    color: white;
    transition: all 0.2s;
}
.socials a:hover {
    color: #EBC849;
}
.socials i {
    font-size: 24px;
    padding: 0px 10px;
}
.scroll-down-arrow {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    transition: all 0.5s ease-in;
  }
  
  .scroll-down-arrow.show {
    transform: translateY(-80px);
  }
.scroll-down-arrow i {
    font-size: 35px;
}
@media screen and (max-width: 768px) {
    main.hero {
        padding-bottom: 10em;
    }
    main.hero div:first-child {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        font-size: 0.4em;
    }
    main.hero div:first-child span:last-child {
        font-size: 2em;
    }
    .socials {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    main.hero div:first-child span:last-child {
        font-size: 2.5em;
    }
}