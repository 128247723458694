/* Homepage contact section */ 
#home-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 8em;
}
#home-contact span {
    margin-bottom: 2em;
}

/* Contact Page */
#contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8em;
}
#contact > div {
    padding-top: 4em;
    width: 100%;
    max-width: 500px;
}
label span {
    margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
    #home-contact span {
        text-align: center;
    }
    #contact {
        width: 90%;
        margin: 0 auto;
    }
    #contact > span {
        text-align: center;
    }
}

@media screen and (max-width: 375px) {
    #home-contact span {
        width: 90%;
    }
}