body {
  font-family: 'Albert Sans', sans-serif;
}
a {
  color: white;
  transition: all 0.3s;
}
a:hover {
  color: #EBC849;
}
h1 {
  font-size: 3em;
  color: #c7493a;
  margin: -10px 0px 10px 0px;
}
h2, h3, h4, h5, h6 {
  color: #D14636;
}
.button {
  color: black;
  background-color: #8896AB;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  width: 120px;
  transition: all 0.3s;
  text-align: center;
}
.button:hover {
  color: #8896AB;
  background-color: white;
}
button {
  color: black;
  background-color: #8896AB;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  width: 120px;
  transition: all 0.3s;
  text-align: center;
}

button:hover {
  color: #8896AB;
  background-color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#root {
  background-color: #282c34;
  background-image: url('./assets/images/stars-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  color: white;
}

.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.hidden a {
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.is-centered {
  display: flex;
  justify-content: center;
}
.subheader {
  color: #D14636;
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 0;
}
 @media screen and (max-width: 768px) {
  h1 {
    font-size: 2.5em;
  }
  .subheader {
    font-size: 1.75em;
}
 }