#about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8em;
    width: 80%;
    margin: 0 auto;
}
.flex-half {
    display: flex;
}
.flex-half img {
    width: auto;
    height: 425px;
    border-radius: 50%;
    margin: 20px;
}
.flex-half div {
    padding: 20px;
}
.flex-half p {
    margin-top: 0px;
}
.flex-half h3 {
    margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
    #about {
        width: 90%;
    }
    .flex-half {
        width: 100%;
        flex-direction: column;
    }
    .flex-half img {
        height: 350px;
        width: 350px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 500px) {
    .flex-half img {
        height: auto;
    }
    .flex-half div {
        padding: 20px 0px;
    }
}