#projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8em;
}
#projects > .subheader {
  display: none;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2em;
    max-width: 1050px; /* Set a max-width to limit the number of columns */
    margin: 0 auto; /* Center the grid horizontally */
    padding: 2em 0 2em 0;
  }
  
  .project {
    padding: 2em;
    text-align: center;
    border-radius: 5px;
    background-color: black;
    border: 1px solid rgba(255,255,255,0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 500px;
  }
  .project p {
    text-align: left;
  }
  .project p span {
    color: #a33327;
    font-weight: bold;
    cursor: pointer;
  }
  .project .tech-used {
    list-style: none;
    display: flex;
    justify-content: center;
    padding-left: 0px;
    margin: 0.5em 0 1.5em 0;
  }
  .project .tech-used li {
    padding: 0px 5px;
    font-size: 24px;
    color: #EBC849;
  }
  .project .tech-used li span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .project img {
    max-width: 100%;
    max-height: 150px;
    min-height: 150px;
    width: 250px;
    object-fit: cover;
  }
  
  .project a {
    display: block;
  }

  #more-projects {
    display: flex;
    justify-content: center;
    padding-bottom: 4em;
  }
  #more-projects .button {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .project-grid {
      width: 90%;
    }
    #projects {
      width: 90%;
      margin: 0 auto;
    }
    #projects > span {
      text-align: center;;
    }
  }